<template>
  <notification-layout
    :icon="notification.icon"
    :time="notification.notify_at"
    :mustBeExplicitlyApproved="true"
    :notification="notification"
    @deleteNotification="deleteNotification"
    v-slot="scope"
  >
    neue Kontaktanfrage von {{ notification.user.first_name }}
    {{ notification.user.last_name }}
    <b-row
      class="notification-chat-buttons"
      v-if="!notification.action_taken && !actionTaken"
    >
      <b-col>
        <b-button
          @click="
            () => acceptRequest(notification.request?.id, scope.hasBeenNotified)
          "
          variant="success"
          class="icon-right"
          >Annehmen<i class="fa-solid fa-user-plus"></i
        ></b-button>
      </b-col>
      <b-col>
        <b-button
          @click="
            () => denyRequest(notification.request?.id, scope.hasBeenNotified)
          "
          variant="danger"
          class="icon-right"
          >Ablehnen<i class="fa-solid fa-xmark"></i
        ></b-button>
      </b-col>
    </b-row>
    <b-row class="notification-chat-buttons" v-else>
      <b-col> Anfrage bearbeitet </b-col>
    </b-row>
  </notification-layout>
</template>
<script>
import gql from "graphql-tag";
import NotificationLayout from "./NotificationLayout.vue";
export default {
  components: { NotificationLayout },
  props: {
    notification: {
      type: Object,
      required: true,
    },
  },
  data() {
    return { actionTaken: false };
  },
  methods: {
    deleteNotification(id) {
      this.$emit("deleteNotification", id);
    },
    async acceptRequest(id, hasBeenNotified) {
      await this.$apollo.mutate({
        mutation: gql`
          mutation AcceptContactRequest(
            $contactRequestId: ID!
            $input: ChatMembershipInput!
          ) {
            acceptContactRequest(id: $contactRequestId, input: $input) {
              id
            }
          }
        `,
        variables: {
          contactRequestId: id,
          input: {
            receive_emails: true,
            receive_notifications: true,
          },
        },
      });
      this.$toast("Anfrage Akzeptiert");
      hasBeenNotified();
      this.setActionTaken();
    },
    async denyRequest(id, hasBeenNotified) {
      await this.$apollo.mutate({
        mutation: gql`
          mutation DenyContactRequest($contactRequestId: ID!) {
            denyContactRequest(id: $contactRequestId) {
              id
            }
          }
        `,
        variables: {
          contactRequestId: id,
        },
      });
      this.$toast("Anfrage Abgelehnt");
      hasBeenNotified();
      this.setActionTaken();
    },
    async setActionTaken() {
      await this.$apollo.mutate({
        mutation: gql`
          mutation NotificationActionTaken($id: ID!) {
            notificationActionTaken(id: $id) {
              id
            }
          }
        `,
        variables: {
          id: this.notification.id,
        },
      });
      this.actionTaken = true;
    },
  },
};
</script>
